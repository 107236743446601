import React from 'react';
import './App.scss';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import DropMail from './containers/DropMail';
import TitleForm from './containers/TitleForm';
import SaveButton from './containers/SaveButton';
import ViewportButton from './containers/ViewportButton';
import { createMuiTheme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import DropTranslation from './containers/DropTranslation';
import ViewportMail from './containers/ViewportMail';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#30c145',
      main: '#289b38',
      dark: '#207d2d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff00ff',
      main: '#00ff00',
      dark: '#00ff00',
      contrastText: '#000',
    },
  },
});


function App() {
  return (
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn Reactxd
    //     </a>
    //   </header>
    <div
      className="container"
    >
      <MuiThemeProvider
        theme={theme}
      >
        <ThemeProvider theme={theme}>

          <div
            className="container__save"
          >
            <SaveButton />

          </div>
          <div
            className="container__drop_translation"
          >
            {/* <Dropzone /> */}
            {/* <TranslationTable /> */}
            <DropTranslation />
          </div>

          <div
            className="container__drop_file"
          >
            <DropMail />
            {/* <FileCard /> */}
          </div>
          <div
            className="container__title"
          >
            <TitleForm />
          </div>
          <div
            className="container__mail"
          >
            <ViewportMail />
          </div>


          <div
            className="container__viewport"
          >
            {/* <ViewportButton /> */}
            <ViewportButton />
          </div>






        </ThemeProvider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
