import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 3 * 4 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

// const countryLabels = [
//     "RAW", "PL", "CZ", "SK", "RO", "HU", "UA", "RU", "BG", "DE", "EU", "LT", "GR", "SE", "IT", "ES",
// ];


const MailContainer = ({ desktopViewport, mailTemplate, mails }) => {



    console.log(mails);


    const useStyles = makeStyles(theme => ({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: desktopViewport ? "700px" : "400px",
            height: "100% !important",
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important",
            // border: "1px solid black"
            borderRadius: "3px !important"
        },
    }));


    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    function handleChangeIndex(index) {
        setValue(index);
    }

    return (
        <div className={classes.root}>
            <AppBar
                position="static"
                color="default"
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        Object.keys(mails).map(country =>
                            <Tab label={country} key={country} />
                        )
                    }

                </Tabs>
            </AppBar>
            <SwipeableViews
                // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >

                {
                    Object.keys(mails).map(country =>
                        <TabContainer
                            dir={theme.direction}
                            key={country}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: mails[country] }}
                                style={{
                                    height: "700px",
                                }}
                            ></div>

                        </TabContainer>
                    )
                }


            </SwipeableViews>
        </div>
    );
};

export default MailContainer;