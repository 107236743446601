import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// const countryLabels = [
//     "RAW", "PL", "CZ", "SK", "RO", "HU", "UA", "RU", "BG", "DE", "EU", "LT", "GR", "SE", "IT", "ES",
// ];

const useStyles = makeStyles(theme => ({
    root: {
        width: '730px',
        // width: '730px',
        // height: "100%",
        height: "380px",
        // marginTop: theme.spacing(3),
        overflow: 'auto',


    },
    table: {
        // overflow: 'auto',
        minWidth: 650,
    },
}));

// function createData(name, calories, fat, carbs, protein, a, b, c, d, e, f) {
//     return { name, calories, fat, carbs, protein, a, b, c, d, e, f };
// }

// const rows = [
//     createData('Frozen yoghurt', "tekst...", "tekst...", "tekst...", "tekst...", "tekst...", "tekst...", "tekst...", "tekst...", "tekst...", "tekst..."),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 6.0, 24, 4.0, 6.0, 24, 4.0),
// ];


const TranslationTable = ({ table, headers }) => {
    const classes = useStyles();



    return (

        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {headers.map((label) =>
                            <TableCell key={label}>{label}</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table.map(row =>

                        <TableRow>
                            {/* <TableCell component="th" scope="row">
                                {row.KEY}
                            </TableCell> */}

                            {Object.keys(row).map((key) => (
                                <TableCell align="left" key={key}>
                                    {row[key]}
                                </TableCell>
                            ))}

                        </TableRow>
                        // <TableRow key={row.KEY}>
                        //     <TableCell component="th" scope="row">
                        //         {row.KEY}
                        //     </TableCell>

                        //     {this.row.map(el =>
                        //         <TableCell align="right">{row[el]}</TableCell>
                        //     )}

                        // </TableRow>

                    )}


                    {/* {rows.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                        </TableRow>
                    ))} */}


                </TableBody>
            </Table>
        </Paper>
    );
};


export default TranslationTable;