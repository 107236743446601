import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { mailOperations } from "../state/ducks/mail";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
    pushed: {
        backgroundColor: "lightgray",
    },

    released: {
        backgroundColor: "initial",
    },

}));

const ViewportButton = ({ desktopViewport, changeViewportToDesktop, changeViewportToMobile }) => {
    const classes = useStyles();

    return (

        <ButtonGroup
            size="medium"
            aria-label="Small outlined button group"
        >
            <Button
                className={desktopViewport ? classes.pushed : classes.released}
                onClick={changeViewportToDesktop}
            >
                <Icon>desktop_windows</Icon>
            </Button>
            <Button
                className={!desktopViewport ? classes.pushed : classes.released}
                onClick={changeViewportToMobile}
            >
                <Icon>smartphone</Icon>
            </Button>
        </ButtonGroup>

    );
};

const mapStateToProps = state => ({
    desktopViewport: state.mailState.desktopViewport,
});

const mapDispatchToProps = {
    changeViewportToDesktop: mailOperations.changeViewportToDesktop,
    changeViewportToMobile: mailOperations.changeViewportToMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewportButton);
