import { mailOperations } from "../state/ducks/mail";
import { translationSelectors } from "../state/ducks/translation";
import { connect } from "react-redux";
import MailContainer from '../components/MailContainer';


const mapStateToProps = state => ({
    desktopViewport: state.mailState.desktopViewport,
    mailTemplate: state.mailState.mailTemplate,
    mails: translationSelectors.getTranslatedMails(
        state.translationState.table,
        state.mailState.mailTemplate,
    ),
});

const mapDispatchToProps = {
    changeViewportToDesktop: mailOperations.changeViewportToDesktop,
    changeViewportToMobile: mailOperations.changeViewportToMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailContainer);
