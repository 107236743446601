// https://material-ui.com/components/text-fields/
// TODO check this out, especially Material UI with Redux Form
// https://mui-treasury.com/components/card
// TODO: Here are some examples of styling MUI
// https://material-ui.com/customization/themes/
// TODO: Probably the easiest way
// https://stackoverflow.com/questions/34952530/i-am-using-redux-should-i-manage-controlled-input-state-in-the-redux-store-or-u
// TODO: tl;dr use library

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from "react-redux";
import { mailOperations } from "../state/ducks/mail";

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: "2.5em",
        // color: theme.palette.primary.main,
    },
    root: {
        fontSize: "2.5em",
        // marginTop: "3em",
        // marginBottom: "3em",
        height: "2em",
    },


}));

const TitleForm = ({ setTitle, title }) => {
    // const [labelWidth, setLabelWidth] = React.useState(0);
    // const [name, setTitle] = React.useState(title);
    const classes = useStyles();



    // function handleChange(event) {
    //     setName(event.target.value);
    // }

    return (
        <FormControl
            // className={classes.formControl}
            fullWidth
        >
            {title}
            <InputLabel
                // htmlFor="component-simple"
                className={classes.label}
            // margin="normal"
            >
                Nazwa maila
            </InputLabel>
            <Input
                className={classes.root}
                // id="component-simple"
                value={title}
                onChange={event => setTitle(event.target.value)}
            />
        </FormControl>
    );
};

const mapStateToProps = state => ({
    desktopViewport: state.mailState.title,
});

const mapDispatchToProps = {
    setTitle: mailOperations.changeMailTitle,//TODO validate
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleForm);

