import mailReducer from "./reducers";
import * as mailTypes from "./types";

export {
    default as mailSelectors
}
    from "./selectors";
export {
    default as mailOperations
}
    from "./operations";
export {
    mailTypes
};

export default mailReducer;