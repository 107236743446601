import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import RootRef from '@material-ui/core/RootRef';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from 'material-ui';
import { connect } from "react-redux";
import { translationOperations, translationSelectors } from "../state/ducks/translation";
import TranslationTable from '../components/TranslationTable';
import Dropzone from '../components/Dropzone';
import Papa from 'papaparse';


// https://codesandbox.io/s/z39y9mlwq4
//TODO: this tbh, redux-form

const styles = {
    root: {
        // width: "600px",
        padding: "20px",
        borderRadius: "5px !important",
        textAlign: "center",
        cursor: "pointer",
    },

    border: {
        borderRadius: "5px !important",
        padding: "30px",
        border: "3px dashed #aeaeae"

    },

};

const DropTranslation = ({ classes, table, updateTable, headers }) => {

    // const reader = new FileReader();

    const onDrop = useCallback(acceptedFiles => {
        Papa.parse(acceptedFiles[0], {
            complete: results => {
                console.log(results);

                updateTable(results.data.filter(row => Object.keys(row).length > 1)); //TODO probably here should check for errors etc
            },
            header: true,
        });

    }, [updateTable]);//TODO: verify if updateTable is necessary here

    // const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { ref, ...rootProps } = getRootProps()


    // reader.onload = () => {
    //     console.log(reader.result);//TODO
    //     // updateMailTemplate(reader.result);
    // };

    return (

        <RootRef
            rootRef={ref}
        >
            <input {...getInputProps()} />
            {
                table ?
                    < TranslationTable
                        table={table}
                        headers={translationSelectors.getCountryHeaders(table)}
                    />
                    :
                    <Paper
                        className={classes.root}
                        {...rootProps}
                    >

                        <Dropzone
                            icon="backupt"
                            title="Dodaj tłumaczenie"
                            subtitle="Przeciągnij tu plik .csv z tłumaczeniem lub"
                        />

                    </Paper>
            }
        </RootRef>

    );
};

const mapStateToProps = state => ({
    table: state.translationState.table,
});

const mapDispatchToProps = {
    updateTable: translationOperations.updateTable,
};

export default
    connect(
        mapStateToProps,
        mapDispatchToProps)(withStyles(styles)(DropTranslation));
