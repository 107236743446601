// import CONSTANTS from "./constants";

// const getVisibleTodos = (todos, filter) => {
//     switch (filter) {
//         case CONSTANTS.SHOW_ALL:
//             return todos;
//         case CONSTANTS.SHOW_COMPLETED:
//             return todos.filter(t => t.completed);
//         case CONSTANTS.SHOW_ACTIVE:
//             return todos.filter(t => !t.completed);
//         default:
//             throw new Error("Unknown filter: " + filter);
//     }
// };

const getCountryHeaders = table => Object.keys(table[0]);

const getTranslatedMails = (table, mailTemplate) => {
    if (!table || !mailTemplate) return { "KEY": "mailTemplate" }; //TODO maybe should made dict from this

    let test = {};

    for (let country of getCountryHeaders(table)) {

        test = { ...test, ...{ [country]: {} } };

        for (let el of table) {
            test[country][el["KEY"]] = el[country];
        }
    }


    console.log(test);
    console.log("table", table);

    let mails = {};

    for (let [country, translations] of Object.entries(test)) {
        let translatedMail = mailTemplate;
        for (let [key, translated] of Object.entries(translations)) {
            translatedMail = translatedMail.replace(RegExp('\\[\\[' + key + '\\]\\]', 'g'), translated);
        }

        // Object.keys(translations).forEach(key => {
        //     translatedMail = translatedMail.replace(new RegExp('\{\{' + key + '\}\}'), data[key]);
        // });

        mails = {
            ...mails,
            ...{ [country]: translatedMail }
        };
    }

    return mails;
};

export default {
    getCountryHeaders,
    getTranslatedMails,
};