// import CONSTANTS from "./constants";
import * as types from "./types";
/* State shape
const initialState = {
    file: {
        name,
        rows,
        columns,
        date,
    },
    table,
};
*/

// this method can be confusing because it serves two purposes:
// 1 - it create a new todo
// 2 - it toggles the completed state of an existing todo


const reducer = (state = {}, action) => {
    switch (action.type) {
        case types.UPDATE_FILE:
            return {
                ...state,
                ...action.payload,
            };
        case types.UPDATE_TABLE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default reducer;