import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {
    Provider as ReduxProvider
} from "react-redux";
import configureStore from "./state/store";

const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

const RootHtml = () => (
    <ReduxProvider store={reduxStore} >
        <App />
    </ReduxProvider>
);


ReactDOM.render(<RootHtml />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();