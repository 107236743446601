import * as types from "./types";

export const changeMailTitle = (title) => ({
    type: types.CHANGE_MAIL_TITLE,
    payload: {
        title,
    },
});

export const changeViewportToDesktop = (viewport) => ({
    type: types.CHANGE_VIEWPORT_TO_DESKTOP,
});

export const changeViewportToMobile = (viewport) => ({
    type: types.CHANGE_VIEWPORT_TO_MOBILE,
});

export const updateMailTemplate = (mailTemplate) => ({
    type: types.UPDATE_MAIL_TEMPLATE,
    payload: {
        mailTemplate,
    },
});