import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import icon_CSV from './../assets/csv_icon.png';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: "auto",
        padding: "30px 50px 30px 50px",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },

    thumb: {
        height: "80px",
    },

    content: {
        paddingLeft: "30px",
        textAlign: "left",
    },

}));

const FileCard = ({ name, rows, columns, date }) => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <img src={icon_CSV} alt="Ikona csv" className={classes.thumb} />
            <div className={classes.content} >
                <Typography variant="h5" component="h3">
                    {name}
                </Typography>
                <Typography component="p">
                    {rows} wiersze tłumaczenia
                    </Typography>

                <Typography component="p">
                    {columns} krajów
                    </Typography>

                <Typography component="p">
                    {date}
                </Typography>

            </div>
        </div>
    );
}

export default FileCard;
