import {
    changeMailTitle,
    updateMailTemplate,
    changeViewportToDesktop,
    changeViewportToMobile,
} from "./actions";

export default {
    changeMailTitle,
    updateMailTemplate,
    changeViewportToDesktop,
    changeViewportToMobile,
};