// import CONSTANTS from "./constants";
import * as types from "./types";
/* State shape*/
const initialState = {
    title: "unknown",
    desktopViewport: true,
    // mailTemplate: "",
};


// this method can be confusing because it serves two purposes:
// 1 - it create a new todo
// 2 - it toggles the completed state of an existing todo


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHANGE_MAIL_TITLE:
            return {
                ...state,
                ...action.payload,
            };
        case types.CHANGE_VIEWPORT_TO_DESKTOP:
            return {
                ...state,
                desktopViewport: true,
            };
        case types.CHANGE_VIEWPORT_TO_MOBILE:
            return {
                ...state,
                desktopViewport: false,
            };

        case types.UPDATE_MAIL_TEMPLATE:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
};

export default reducer;