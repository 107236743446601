import * as types from "./types";

export const updateFile = (name, rows, columns, date) => ({
    type: types.UPDATE_FILE,
    payload: {
        file: {
            name,
            rows,
            columns,
            date,
        },
    },
});

export const updateTable = (table) => ({
    type: types.UPDATE_TABLE,
    payload: {
        table,
    },
});