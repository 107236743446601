import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        // width: "600px",
        height: "100%",
        padding: "20px",
        borderRadius: "5px !important",
        textAlign: "center",
        cursor: "pointer",
        boxSizing: "border-box",
        border: "3px dashed #aeaeae"
    },

    border: {

    },
};

const Dropzone = ({ classes, icon, title, subtitle }) => {


    return (

        <div
            className={classes.root}
        // className={classes.border}
        >

            <Icon
                className={classes.icon}
            >
                {icon}
            </Icon>

            <Typography
                variant="h5"
            >
                {title}
            </Typography>
            <Typography
                variant="body2"
            >
                {subtitle}
            </Typography>

            <Button
                variant="outlined"
                component="span"
                className={classes.button}
            >
                Wybierz z komputera
            </Button>


        </div>


    );
};

export default withStyles(styles)(Dropzone);