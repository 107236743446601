import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { translationSelectors } from "../state/ducks/translation";

import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

const save = (mails, title) => {

    let zip = new JSZip();

    for (let [country, body] of Object.entries(mails)) {
        zip.file(`${title}_${country}.html`, body);
    }

    zip.generateAsync({ type: "blob" }).then(content => {
        saveAs(content, `${title}.zip`);
    });

};

const SaveButton = ({ mails, title }) => {
    const classes = useStyles();

    return (
        <Button
            variant="contained"
            size="large"
            className={classes.button}
            color="primary"
            onClick={() => save(mails, title)}
        >
            <Icon
                className={clsx(classes.leftIcon, classes.iconSmall)}
            >save</Icon>
            Pobierz MAILE
      </Button>
    );
}


const mapStateToProps = state => ({
    mails: translationSelectors.getTranslatedMails(
        state.translationState.table,
        state.mailState.mailTemplate,
    ),
    title: state.mailState.title,
});

export default connect(mapStateToProps)(SaveButton);

