import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import RootRef from '@material-ui/core/RootRef';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from 'material-ui';
import { connect } from "react-redux";
import { translationOperations } from "../state/ducks/translation";
import { mailOperations } from "../state/ducks/mail";
import FileCard from '../components/FileCard';
import Dropzone from '../components/Dropzone';

// https://codesandbox.io/s/z39y9mlwq4
//TODO: this tbh, redux-form

const styles = {
    root: {
        // width: "600px",
        padding: "20px",
        borderRadius: "5px !important",
        textAlign: "center",
        cursor: "pointer",
    },

    border: {
        borderRadius: "5px !important",
        padding: "30px",
        border: "3px dashed #aeaeae"

    },

    thumb: {
        height: "80px",
    },

    content: {
        paddingLeft: "30px",
        textAlign: "left",
    },
};

const DropMail = ({ classes, file, updateFile, changeMailTitle, updateMailTemplate }) => {

    const reader = new FileReader();

    const onDrop = useCallback(acceptedFiles => {
        updateFile(acceptedFiles[0].name, 15, 20, acceptedFiles[0].lastModified);
        reader.readAsBinaryString(acceptedFiles[0]);
        changeMailTitle(acceptedFiles[0].name);
    }, [changeMailTitle, reader, updateFile]);

    const {
        // acceptedFiles, 
        getRootProps,
        getInputProps
    } = useDropzone({ onDrop });
    const { ref, ...rootProps } = getRootProps()


    reader.onload = () => {
        if(reader.result.includes("eobuwie")){
            updateMailTemplate(reader.result);
        }
    };

    // const files = acceptedFiles.map(file => (
    //     <li key={file.path}>
    //         {file.name}, {file.path} - {file.size} bytes

    //         {reader.readAsText(file)}

    //         {/* <div id="xd" /> */}
    //     </li>
    // ));

    return (

        <RootRef
            rootRef={ref}
        >
            <Paper
                className={classes.root}
                {...rootProps}
            >
                <input {...getInputProps()} />
                {
                    file ?

                        <FileCard
                            name={file.name}
                            rows={file.rows}
                            columns={file.columns}
                            date={file.date}
                        /> :

                        <Dropzone
                            icon="attachment"
                            title="Dodaj szablon"
                            subtitle="Przeciągnij tu plik .html z szablonem maila do tłumaczenia lub"
                        />

                }
            </Paper>
        </RootRef>

    );
};

const mapStateToProps = state => ({
    file: state.translationState.file,
});

const mapDispatchToProps = {
    updateFile: translationOperations.updateFile,//TODO validate
    changeMailTitle: mailOperations.changeMailTitle,//TODO not work xD
    updateMailTemplate: mailOperations.updateMailTemplate,
};

export default
    connect(
        mapStateToProps,
        mapDispatchToProps)(withStyles(styles)(DropMail));
